import type AuthScope from '~/enums/auth-scope'

const messages = {
  failedToDownloadTable: 'Не удалось скачать таблицу.',
  tableIsExportedToEmail: 'Таблица экспортирована на email.'
}

export function useExporting () {
  const { $apiHelper, $toast } = useNuxtApp()

  function downloadFile (response: any, filename: string, type: string): void {
    const blob = new Blob([response], { type })

    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(blob, filename)
    } else {
      const anchorElement = document.createElement('a')

      if (anchorElement.download === undefined) {
        anchorElement.setAttribute('target', '_blank')
      }

      const objectUrl = URL.createObjectURL(blob)
      anchorElement.setAttribute('href', objectUrl)
      anchorElement.setAttribute('download', filename)

      anchorElement.style.display = 'none'

      document.body.appendChild(anchorElement)

      anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))

      document.body.removeChild(anchorElement)

      setTimeout(() => URL.revokeObjectURL(objectUrl), 100)
    }
  }

  async function exportData (
    authScope: AuthScope,
    exportName: string,
    filename: string,
    data: any = {}
  ): Promise<void> {
    try {
      const response = await $apiHelper.actions.exportData(exportName, authScope, data)

      if (data?.exportDataToEmail === 'yes') {
        $toast.success(messages.tableIsExportedToEmail)

        return
      }

      downloadFile(response, filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    } catch (error) {
      console.error(error)

      $toast.error(messages.failedToDownloadTable)
    }
  }

  return { downloadFile, exportData }
}
